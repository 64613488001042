<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Rates</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Fees:</h2>
                        <p>Initial "Sniff Me Out" meeting: No charge. We want to get to know you and your pet, and earn your business. Please see our services for our full menu of care options!</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Daily Visits:</h2>
                        <ul>
                            <li>Dogs (up to 2): $20.50 per visit, $3.50 each additional dog; No charge for “sibling” cats</li>
                            <li>Cats (no limit): $20.50 per visit</li>
                            <li>Additional charges may apply for extensive clean-up or specialized services</li>
                            <li>Extended daily visits quoted upon request</li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Vacation Care:</h2>
                        <ul>
                            <li>Dogs (up to 2), $20.50 per visit, $3.50 each additional dog; sibling cats, no charge</li>
                            <li>Cats only (up to five) $20.50 per visit; over five cats quoted upon request</li>
                            <li>Small animals: $3.00 per day (in conjunction with dog or cat care). No charge for fish.</li>
                            <li>Extended visits quoted upon request.</li>
                        </ul>
                        <p>Additional charges may apply for extensive clean-up or specialized services.</p>
                        <p>Current Medical POA naming PGP or a local family member/friend must be on file - PGP can provide a template if your vet does not have a practice-specific care release.</p>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Stork Service:</h2>
                        <p>$18 per visit</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Service Area</h2>
                        <p>Our pawprint covers Liberty Township, West Chester, Fairfield township as well as western and southern Mason.</p>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">In-Home Buddy Care:</h2>
                        <p>We can care for your pets in your home, overnight, in conjunction with daily vacation visits. Add $27.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Pet Taxi Service:</h2>
                        <p>Quoted upon request (time and mileage). Please make sure that PGP has appropriate authorizations with your service providers before scheduling this service. </p>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">24/7 Emergency Service:</h2>
                        <p>Missed a flight? Weather delaying your return? We offer 24/7 emergency service to Club Princess members. Click on the Club Princess icon for details.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Club Princess:</h2>
                        <p>Use Princess Gentle Paws Pet Sitting and Care at least 12 days per month and you qualify for special Club Princess benefits. Click on the Club Princess icon for details!</p>
                    </div>
                </div>-->

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Holiday Bookings:</h2>
                        <p>We reserve the right to request a 50% deposit for holiday sits, payable at the time of booking. Holidays include Christmas, Thanksgiving, New Year’s, Easter, July 4, Memorial Day, Labor Day. Thank you for understanding and respecting our holiday booking policy.</p>
                    </div>
                </div>

            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Rates', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>