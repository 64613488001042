import Home from './components/pages/0-Home.vue';
import AboutUs from './components/pages/1.0-AboutUs.vue'
import Staff from './components/pages/1.1-Staff.vue'
import JoinThePack from './components/pages/1.2-JoinThePack.vue'
import Services from './components/pages/2.0-Services.vue'
import Area from './components/pages/2.1-Area.vue'
import Rates from './components/pages/2.2-Rates.vue'
import Policies from './components/pages/2.3-Policies.vue'
import WaggingTails from './components/pages/3.0-WaggingTails.vue'
import BarkAtUs from './components/pages/4.0-BarkAtUs.vue'
import News from './components/pages/5.0-News.vue'

export const routes = [
    {path: '/', component: Home},
    {path: '/about-us', component: AboutUs},
    {path: '/staff', component: Staff},
    {path: '/join-the-pack', component: JoinThePack},
    {path: '/services', component: Services},
    {path: '/area', component: Area},
    {path: '/rates', component: Rates},
    {path: '/policies', component: Policies},
    {path: '/wagging-tails', component: WaggingTails},
    {path: '/bark-at-us', component: BarkAtUs},
    {path: '/news', component: News}
];