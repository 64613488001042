<template>
    <div id="main-container" class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9 p-0">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 3.jpg" alt="First slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 8.jpg" alt="Second slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 7.jpg" alt="Second slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 2.jpg" alt="Third slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 4.jpg" alt="Fourth slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 5.jpg" alt="Fifth slide">
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src="../../../../src/assets/carousel/2024-banner 6.jpg" alt="ixth slide">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <!-- col-sm-12 col-md-12 col-lg-12 col-xl-9 -->
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 p-4 ppg-bg-darkblue text-light">
                <h2 class="text-center mb-4">Daily Pet Care</h2>
                <div class="btn-group-vertical d-flex flex-wrap">
                    <button type="button" class="btn btn-primary btn-lg btn-block mb-2 text-left">Dogs</button>
                    <button type="button" class="btn btn-primary btn-lg btn-block mb-2 text-left">Puppies</button>
                    <button type="button" class="btn btn-primary btn-lg btn-block mb-2 text-left">Cats</button>
                    <button type="button" class="btn btn-primary btn-lg btn-block mb-2 text-left">Small Animals</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>