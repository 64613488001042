<template>

    <nav :id=name class="navbar navbar-expand-lg navbar-dark ppg-bg-darkblue mb-4">
        <a class="navbar-brand d-sm-block d-md-block d-lg-none d-xl-none" href="#">Menu</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
                <router-link to="/" tag="li" active-class="active" exact><button class="nav-link" @click="btnDropdownItem">Home</button></router-link>
                <li class="nav-item dropdown">
                    <a ref='AboutUsTop' v-bind:class="{active:isAboutUsActive}" class="nav-link dropdown-toggle" href="/about-us" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="btnDropdownItem">
                        About Us
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <router-link to="/about-us" tag="li" active-class="active" exact><button ref='AboutUsDrop' class="dropdown-item" @click="btnDropdownItem">About Us</button></router-link>
                        <router-link to="/staff" tag="li" active-class="active" exact><button ref='StaffDrop' class="dropdown-item" @click="btnDropdownItem">Staff</button></router-link>
                        <router-link to="/join-the-pack" tag="li" active-class="active" exact><button ref='JoinDrop' class="dropdown-item" @click="btnDropdownItem">Join the Pack!</button></router-link>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a ref='ServicesTop' v-bind:class="{active:isServicesActive}" class="nav-link dropdown-toggle" href="/services" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <router-link to="/services" tag="li" active-class="active" exact><button ref="ServicesDrop" class="dropdown-item" @click="btnDropdownItem">Services</button></router-link>
                        <router-link to="/area" tag="li" active-class="active" exact><button ref='AreaDrop' class="dropdown-item" @click="btnDropdownItem">Service Area</button></router-link>
                        <router-link to="/rates" tag="li" active-class="active" exact><button ref='RatesDrop' class="dropdown-item" @click="btnDropdownItem">Rates</button></router-link>
                        <router-link to="/policies" tag="li" active-class="active" exact><button ref='PoliciesDrop' class="dropdown-item" @click="btnDropdownItem">Policies</button></router-link>
                        <!--<router-link to="/club-princess" tag="li" active-class="active" exact><button ref='ClubDrop' class="dropdown-item" @click="btnDropdownItem">Club Princess</button></router-link>-->
                    </div>
                </li>
                <router-link to="/wagging-tails" tag="li" active-class="active" exact><button class="nav-link" @click="btnDropdownItem">Wagging Tails</button></router-link>
                <router-link to="/bark-at-us" tag="li" active-class="active" exact><button class="nav-link" @click="btnDropdownItem">Bark At Us</button></router-link>
                <router-link to="/news" tag="li" active-class="active" exact><button class="nav-link" @click="btnDropdownItem">In The News</button></router-link>
            </ul>
        </div>
    </nav>

</template>

<script>
export default {
  name: 'PPGNav',
  props: ['name'],
  data: function(){
      return{
        isAboutUsActive: false,
        isServicesActive: false,
        arrButtons:[
            {top: "AboutUsTop", children: ["AboutUsDrop", "StaffDrop", "JoinDrop"]},
            {top: 'ServicesTop', children: ['ServicesDrop', 'AreaDrop', 'RatesDrop', 'PoliciesDrop', 'ClubDrop']}
        ]
      }
  },
  methods: {
      btnDropdownItem(event){
        console.log("btnDropdownItem");

        var tempParent = undefined;
        for (var e=0; e<this.arrButtons.length; e++){
            for (var f=0; f<this.arrButtons[e].children.length; f++){
                if(event.target == this.$refs[this.arrButtons[e].children[f]]){
                    console.log("arrButtons[e].top: " + this.arrButtons[e].top)
                    console.log("arrButtons[e].children[f]: " + this.arrButtons[e].children[f])
                    tempParent = this.arrButtons[e].top;
                }
            }
        }
        if(tempParent == "AboutUsTop"){
            this.isAboutUsActive = true;
            this.isServicesActive = false;
        } else if (tempParent == "ServicesTop"){
            this.isAboutUsActive = false;
            this.isServicesActive = true;
        }else{
            this.isAboutUsActive = false;
            this.isServicesActive = false;
        }
      }
  },
  computed: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">

    $lightblue: #41aff4;
    $blue: #1e79d2;
    $bluerollover: #1966b1;
    $darkblue: #061d61;
    $pink: #f7006e;
    
    .nav-link {
        color: $lightblue !important;
        background-color: transparent;
        border: none;
        &:hover {
            color: #fff !important;
            text-decoration: none;
        }
        &:focus{
            outline:none;
        }
    }

    .router-link-exact-active button{
        color: #fff !important;
    }
    
    .nav-item.dropdown {
        .nav-link {
            color: $lightblue !important;
        }
        .nav-link.active{
            color: #fff !important;
        }
        .nav-link:hover {
            color: #fff !important;
        }
    }

    .dropdown-menu{
        .dropdown-item{
            color: $blue !important;
        }
        .dropdown-item:hover{
            color: #fff !important;
            text-decoration: none;
            background-color: $darkblue;
        }
        .dropdown-item:active, .router-link-exact-active button{
            color: #fff !important;
            text-decoration: none;
            background-color: $blue;
        }
    }

</style>