<template>
    <div>
        <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
            <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
                <h1 class="m-0">Wagging Tails.</h1>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Lynne-H.png" alt="Lynne H">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Princess Gentle Paws was initially recommended by our vet… so thrilled she was available that first time.  Elsie and Floyd are so well taken care of… in the best way possible. Gail leaves fabulous notes for us and is so helpful in watching our home when we are away.  We are disappointed when we don’t plan ahead and have to ask someone else! Lynne H</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Tami-T.png" alt="Tami T">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>We choose Princess Gentle Paws and Gail for one simple reason.  We love to travel, but if I didn’t have Gail, who I trust unconditionally with my little furbaby, I wouldn’t go anywhere. Tami T</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Kelley-R.png" alt="Kelley R">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>We Chose PGP after a great recommendation from our vet.  I feel so much better traveling when I know Frankie will be hanging with Gail. Kelley R</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Kelly-B.png" alt="Kelly B">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>We have two cats… one of which needs twice-daily, special-handling medications.  They also have a somewhat complicated feeding regimen. We know the kids are in great hands, and most importantly  that Gail is confident in administering Artemis’ meds.  We are so lucky to have found Princess Gentle Paws. Kelly B</p>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/2165.png" alt="Becky P">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thank you for the care and attention you provide. It is easy to see why you have so many favorable reviews and loyal clients – we are to be included! Becky P.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Riley-P.png" alt="Riley P">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks for taking such great care of Riley! He had such a great time. We will definitely schedule a play date soon. Becky P.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Riley-K.png" alt="Riley K">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>We (Riley and I) thank you for your wonderful care during a stressful time for Riley. She couldn’t have been in better hands. Thank you! Mary K.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Ozzy.png" alt="Gina">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Gail—I knew Ozzy would be so happy with you! It was wonderful that I didn’t have to worry! Gina H.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Ernie-and-Albert.png" alt="Leslie N">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thank you for allowing our families to be together to welcome our daughter into the world! It was so reassuring knowing that you were watching over our furry family. Leslie N.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Zazu.png" alt="Kayla G">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks for all you do for my animals! Kayla G.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Apollo.png" alt="Mary D">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks so much Gail—I really appreciate you taking great care of Apollo following his operation. It gave me great assurance to know that you were checking in on him and giving him good time for recreation. Mary D.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Chenk.png" alt="Debbie B">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thank you so much for taking care of all my animals—I appreciate it! I will give Chenks a big kiss from you! Debbie B.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Wiggles.png" alt="Betty M">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>We just got in and everything is great, as usual! Thank you! Betty M.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Votto.png" alt="Lindsay S">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thank you so much for helping out with Votto when he was sick—he definitely got spoiled today seeing you twice! Lindsay S.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Oliver-R.png" alt="Kelly R">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>You are working miracles with Oliver, thanks! Kelly R.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Zoe.png" alt="Nicole G">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks for everything, Gail. Three happy campers as usual! Nicole G.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/bella-medium.png" alt="Jean A">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>I’ll happily recommend Princess Gentle Paws to anyone looking for a pet sitter! Thanks for taking such good care of her during the storms—she gets so nervous. Thanks for a great experience for Bella! Jean A.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Uma-medium.png" alt="Lisa C">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks for going out of your way to make sure Uma special needs were met. You have always exceeded my expectations. It’s so evident that you are dedicated to animals and that they are a huge priority in your life! Lisa C.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/Jake-medium.png" alt="Theresa F">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Jake is older and has some special needs, and Gail was extremely diligent in caring for those needs. I could relax on my vacation knowing our boy was happy and in great hands. Loved that she would send photos and texts to us every day so we knew what Jake was up to while we were gone! He appeared to be on his own vacation! Theresa F.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8 p-2">
                            <img class="d-block w-100" src="../../../src/assets/wagging-tails/1869.png" alt="Kate M">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 p-2 mt-4">
                            <p>Thanks for everything. I know Maak and Louie are much happier staying at home. We will be calling you next time the four of us travel together! Kate M.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 p-2 mt-4">
                            <p>Thanks again for calling us yesterday – you saved us from coming home to a waterless house! Gabe R.</p>
                        </div>
                    </div>

                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'WaggingTails', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>