<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Service Area</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                <div class="row">
                    <div class="col-12 p-2">
                        <img class="d-block w-100 mb-4" src="../../../src/assets/PPG Service Area.png" alt="Princess Gentle Paws">
                        <h2 class="ppg-text-darkblue">Service Area</h2>
                        <p>Our pawprint covers Liberty Township, West Chester, and Fairfield Township. From St. Maximillian Church, I serve clients within six miles in all directions.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Hours of Operation:</h2>
                        <p>Princess Gentle Paws operates 365 days a year. Our regular business hours are 7:30 am – 10:30 pm.</p>
                    </div>
                </div>
                
            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Area', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>