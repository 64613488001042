<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Services</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row">

                    <div class="col-12 p-2">
                        <img class="d-block w-100 mb-4" src="../../../src/assets/New 2024 9.jpg" alt="Princess Gentle Paws">
                        <h2 class="ppg-text-darkblue">Feet and Fins</h2>
                        <p>Princess Gentle Paws Pet Sitting and Care cares for dogs, cats, fish, birds and small pets (guinea pigs, hamsters, rabbits, etc.). Sorry, we do not care for reptiles or exotics. We reserve the right to decline service to any aggressive animal.</p>
                    </div>

                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Services:</h2>
                        <p>Daily visits include:</p>
                        <ul>
                            <li>Dogs: 30 minutes of exercise, in the yard or via walks, play, and love</li>
                            <li>Puppies: 30 minutes of puppy romping, crate paper change, housebreaking assistance, and hugs and kisses</li>
                            <li>Cats: 30 minutes of kitty play and affection</li>
                            <li>Water bowl refresh</li>
                            <li>Feeding (if required)</li>
                            <li>Litter box scoop and waste removal</li>
                            <li>“Accident” clean-up</li>
                            <li>Medication administration</li>
                            <li>Courtesy mail, paper and indoor/outdoor light service</li>
                            <li>Daily report via e-mail, text, call or written report</li>
                        </ul>
                    </div>

                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Vacation Care:</h2>
                        <p>Includes three – four daily visits plus:</p>
                        <ul>
                            <li>Courtesy trash day service</li>
                            <li>Poop pick-up and removal (for days gone)</li>
                            <li>Cage cleaning (small animals)</li>
                            <li>Plant watering (indoor/outdoor)</li>
                        </ul>
                    </div>

                    <div class="col-12 p-2">
                        <img class="d-block mb-4" src="../../../src/assets/stork.gif" alt="Stork Service">
                        <h2 class="ppg-text-darkblue">Stork Service:</h2>
                        <p>Are you expecting a two-legged addition? Plan ahead so any authorized family member can make the call to PGP when the stork is circling and you need to leave home quickly. We will worry about your pets so you can worry about the college tuition to come!</p>
                    </div>

                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">In-Home Buddy Care:</h2>
                        <p>Does your pet need a human companion to stay with them in the evenings? Please ask about this in-home service.</p>
                    </div>

                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Pet Taxi:</h2>
                        <p>We offer a local Pet Taxi service. If your companion has an appointment at the groomer, daycare or veterinarian’s office, we can transport your pet, and even hold their paw for comfort during their appointments. Available Monday-Friday, by reservation. Please ask about this service.</p>
                    </div>
                    
                </div>
            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Services', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>