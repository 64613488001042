<template>
    <div>
        <Slider></Slider>
        <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
            <div class="mb-4 p-4 rounded ppg-bg-pink text-light">
                <h1 class="m-0">We will reliably care for your pet as if it was our own.</h1>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                    <div class="row">
                        <div class="col-12 p-2">
                            <h2 class="ppg-text-darkblue">Why Princess Gentle Paws Pet Sitting and Care?</h2>
                            <p>Princess Gentle Paws Pet Sitting and Care has been a dream of Gail Wunderlin’s for several years. She is passionate about delighting our four… and two-legged clients. You can work or travel with total peace of mind, knowing that your pets are in loving hands. You are assured that Gail will be visiting your pet each day. From a practical standpoint, in-home companion care is the best alternative for your pet:</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">Puppy Up!</h2>
                            <p>Have a new addition to the pack? We know pups, and will get them started on the right paw!</p>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">Reduced Stress</h2>
                            <p>Your pet remains in the security and comfort of his/her familiar surroundings.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">Well Being</h2>
                            <p>Your pet will not be exposed to illnesses from other animals, or to the stresses that can occur from boarding. Your pet’s customary diet and exercise/play routines are followed, with no interruption to upset your furry friend!</p>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">Continuity for Older Animals</h2>
                            <p>As animals enter their golden years or home hospice, continuity in surroundings is key. Gail has extensive experience with pets in “end of life” or critical healthcare situations and can provide or contract specialized care.</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">PetTech Pet Saver</h2>
                            <img class="d-block w-100 p-4" src="../../../src/assets/PetTech Pet Saver.png" alt="PetTech Pet Saver">
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2 pt-4">
                            <h2 class="ppg-text-darkblue">2014 PSI Adoption Advocate of the Year</h2>
                            <img class="d-block w-100 p-4" src="../../../src/assets/2014 Pet Adoption Advocate.png" alt="2014 PSI Adoption Advocate of the Year">
                        </div>
                    </div>
                    
                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Home', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>