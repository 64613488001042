<template>
    <div>
        <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
            <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
                <h1 class="m-0">Bark At Us</h1>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                    <div class="row">
                        <div class="col-12 p-2">
                            <img class="d-block w-100 mb-4" src="../../../src/assets/Colt-Addyson.png" alt="Princess Gentle Paws">
                            <p>To request a consultation, information, references, or to provide us feedback, send us an <a href="mailto:princessgentlepaws@gmail.com">email</a>, call us at 513.484.5937, or find us on Facebook.</p>
                        </div>
                    </div>
                    
                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'BarkAtUs', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>