<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Policies</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Payment Policies:</h2>
                        <p>We bill clients on a monthly basis, and accept cash, check or Venmo @princessgentlepaws. Last minute cancellations may subject to fees up to $50. Vacation cancels must be received 48 hours prior to departure or deposit will be forfeited.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Key Policy:</h2>
                        <p>All client keys are numerically coded and stored in a lockbox. Your family’s security is of the utmost concern.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Weather Guidelines:</h2>
                        <p>We exercise dogs in all weather with the following exceptions: dangerous ice, life-threatening cold (based on wind chill temps), excessive heat (ambient temperature plus humidity) or an active thunderstorm. During those times we will safely toilet your dog and then have fun play indoors. We are happy to discuss our specific “bad weather” policies and exercise plans with you. We also know that certain breeds are more sensitive to heat, humidity and cold and take every precaution to provide a comfortable care experience for every pet. The safety and enjoyment of your companions is our top priority.</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Pet Medical Power of Attorney:</h2>
                        <p>All vacation clients must have a current Medical POA naming Gail Wunderlin of Princess Gentle Paws Pet Sitting and Care, LLC and/or a local family member on file. Princess Gentle Paws can provide a template if your vet does not have a practice-specific care release.</p>
                    </div>
                </div>

            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Policies', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>