<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Join the Pack</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row">

                    <div class="col-12 p-2">
                        <img class="d-block w-100 mb-4" src="../../../src/assets/Cat.png" alt="Princess Gentle Paws">
                        <p>Princess Gentle Paws is looking for the pick of the litter to join our team of exceptional care providers. PGP is dedicated to the complete delight of its four and two-legged clients. If you have a passion for serving pets and the people who love them, have a strong background in companion animal care and customer service, are willing to provide care on evenings and holidays, are able to run/jog/walk dogs and can pass the stringent Princess Gentle Paws sniff tests (including, but not limited to criminal background check and drug test), then bark at us at princessgentlepaws at gmail.com or message us on Facebook.</p>
                    </div>
                    
                </div>
            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'JoinThePack', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>