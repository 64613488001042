<template>
    <div>
        <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
            <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
                <h1 class="m-0">In The News</h1>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="row">
                        <div class="col-12 p-2">
                            <h2 class="ppg-text-darkblue">Looking for a new furry friend?</h2>
                            <img class="d-block w-100" src="../../../src/assets/news/Purrfect Friends GRRAND.jpg" alt="Reach Out Lakota Pet Food Drive">
                            <p>Here are two rescues that I volunteer with that do wonderful work:</p>
                            <a class="btn btn-primary text-light" href="https://grrand.org/" target="_blank">GRRAND</a>
                            <a class="btn btn-primary text-light" href="http://www.purrfectfriendscatrescue.org/" target="_blank" style="margin-left:10px;">Purrfect Friends Cat Rescue</a>
                            <hr class="border border-2 mt-4">
                        </div>
                        <div class="col-12 p-2">
                            <h2 class="ppg-text-darkblue">Taking the “pet” out of “petrified”</h2>
                            <img class="d-block w-100" src="../../../src/assets/news/FF-News-Banner.png" alt="Reach Out Lakota Pet Food Drive">
                            <p>Fear Free's mission is to prevent and alleviate fear, anxiety & stress in pets by inspiring and educating the people who care for them. The fear free program has enabled me to tap into the emotions, feelings, fears and anxieties that my client's pets feel when they are alone, ill or stressed.  By better understanding their feelings and motivations, I can create an environment free of FAS (fear, anxiety and stress). It has been a joy to watch previously anxious pets blossom into happy, receptive, special creatures!</p>
                            <a class="btn btn-primary text-light" href="https://fearfreepets.com/" target="_blank">Read More</a>
                            <hr class="border border-2 mt-4">
                        </div>
                        <div class="col-12 p-2">
                            <h2 class="ppg-text-darkblue">Reach Out Lakota Pet Food Drive</h2>
                            <img class="d-block w-100" src="../../../src/assets/news/Lakota Pet Food Drive.png" alt="Reach Out Lakota Pet Food Drive">
                            <p>Princess Gentle Paws partners with VCA Lakota Hills Animal Hospital to provide pet food to Reach Out Lakota, a non-profit food and supply pantry that serves the citizens of Butler County.  We are the sole provider of pet food to the pantry, donating, in a typical month, 300-400 pounds of food.  We donate small bags directly, and break down larger bags, so as to serve as many in need as possible. With the onset of Covid-19, the need for donations has only increased, so we quickly moved to a physical collection model complimented by an Amazon Wish List to benefit Reach Out Lakota.  We are thrilled to still be able to safely interact with our community and clients! Our philosophy is to provide loving pet parents a hand up, not a handout.  We feel strongly that keeping pets in homes benefits families and reduces the shelter/rescue population.</p>
                            <a class="btn btn-primary text-light" href="https://www.amazon.com/hz/wishlist/ls/1JUDE20SSAQZJ?ref_=wl_share" target="_blank">Read More</a>
                            <hr class="border border-2 mt-4">
                        </div>
                        <div class="col-12 p-2">
                            <h2 class="ppg-text-darkblue">Pet Insurance Saves Pets and Pocketbooks</h2>
                            <img class="d-block w-100" src="../../../src/assets/news/Pet Insurance.png" alt="WLWT News 5 Interview">
                            <p>Pet insurance has been available privately for some time now, and recently more companies have started offering it to employees in benefits packages. Places like P&G and Kroger are using third-party pet insurance to try and attract new employees and retain great ones. The offer will help protect pets, but also your wallet.</p>
                            <p>Pet owner Gail Wunderlin wanted to make sure both her golden retrievers were taken care of.</p>
                            <a class="btn btn-primary text-light" href="https://www.wlwt.com/article/pet-insurance-saves-pets-and-pocketbooks/13794146" target="_blank">Read More</a>
                            <hr class="border border-2 mt-4 mb-4">
                        </div>
                    </div>
                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'News', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>