<template>
    <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
        <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
            <h1 class="m-0">Staff</h1>
        </div>
        <div class="row mx-0 mb-2">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row">

                    <div class="col-12 p-2">
                        <h2 class="ppg-text-darkblue">Dedication to Customer Delight</h2>
                        <p>Gail has an extensive background in customer care, and is committed to providing you and your pet an optimal experience. Whether it be our use of communication and reporting technology, our customer-centric services and policies or our vast experience with companion animals, you can be sure that you have made the right choice for you and your pets. Gail stakes her reputation on your satisfaction.</p>
                    </div>

                    <div class="col-12 p-2">
                        <img class="d-block w-100 mb-4" src="../../../src/assets/New 2024 2.jpg" alt="Princess">
                        <h2 class="ppg-text-darkblue">Gail Wunderlin</h2>
                        <h3 class="ppg-text-darkblue">Big Dog (President and Pet Companion)</h3>
                        <p>Gail has had a strong love of animals for her entire life. She has owned purebreds and rescues, dogs and cats, puppies and elders, puppy mill survivors and medically challenged. Gail has extensive experience in complicated medical case care, including HW treatment and end of life care. She is pet first aid certified.</p>
                        <p>She currently is the longest-tenured Cincinnati volunteer for GRAND, volunteers for Purrfect Friends Cat Rescue, manages (partnering with VCA Lakota Hills Animal Hospital) the largest small-business pet food drive in the city to benefit Reach Out Lakota, and has provided pet therapy with her beloved golden Colt to Beckett Springs Hospital and other groups.</p>
                        <p>Gail has been profiled in several publications, radio shows and television segments.</p>
                        <p>She is currently a human servant to two GRAND goldens, Addyson and Carly, and four insane rescue cats, Carl, Alexander Hamilton, Wednesday (aka "the Creature") and Claudia.</p>
                        <p>Before trading her pearls for collars, Gail held executive positions with SC Johnson and Sons, Inc., McKesson and Convergys.</p>
                    </div>

                    <div class="col-12 p-2">
                        <p>Princess Gentle Paws Pack is looking to expand the pack. Join us!</p>
                    </div>

                    <div class="col-12 p-2">
                        <p>We do not subcontract, and if we cannot serve you will be happy to provide a reputable referral</p>
                    </div>
                    
                </div>
            </div>
            <Sidebar></Sidebar>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'Staff', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>