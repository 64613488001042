<template>
    <div :id=name class="container mt-4 mb-4 ppg-bg-darkblue p-4 text-light rounded">
        <div class="row d-flex">
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 ppg-border-blue align-self-center">
                <router-link to="/" tag="a" exact><a><img src="../assets/logo-secondary.png" alt="" class="img-fluid"></a></router-link>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 ppg-border-blue align-self-center">
                <h3>Bark At Us!</h3>
                <p>7175 St. Alban's Way<br>
                Liberty Twp, OH 45011<br>
                P: (513) 484-5937</p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 ppg-border-blue align-self-center">
                <h3>Social Media</h3>
                <p><a href="http://www.facebook.com/princessgentlepaws" target="_blank">Follow Us on Facebook!</a></p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 text-center p-4 align-self-center">
                <img src="../assets/princess-footer.png" alt="" class="img-fluid">
            </div>
        </div>
    </div>    
</template>

<script>
export default {
  name: 'Footer',
  props: ['name']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>