<template>
    <div>
        <div id="main-container" class="container p-4 mt-4 ppg-bg-white rounded">
            <div class=" mb-4 p-4 rounded ppg-bg-pink text-light">
                <h1 class="m-0">About Us</h1>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-9">

                    <div class="row">
                        <div class="col-12 p-2">
                            <img class="d-block w-100 mb-4" src="../../../src/assets/princess1.png" alt="Princess">
                            <h2 class="ppg-text-darkblue">Who is Princess Gentle Paws?</h2>
                            <p>Princess, for whom Princess Gentle Paws Pet Sitting and Care is named, was an abandoned, skinny, heartworm positive golden retriever. Through intensive medical care and the power of love, Princess became a 68 pound healthy, happy dog until she tragically and unexpectedly succumbed to sarcoma in March of 2012. PGP honors the memory of our special and beloved Princess, whose paw Gail still feels on her shoulder, guiding her every day. Our philosophy is that all animals respond to gentle care, positive boundaries, secure surroundings and humans they can trust.</p>
                        </div>
                    </div>
                    
                </div>
                <Sidebar></Sidebar>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from './parts/Slider.vue'
import Sidebar from './parts/Sidebar.vue'

export default {
  name: 'AboutUs', 
  components: {
    Slider,
    Sidebar
  },
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>