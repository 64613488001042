<template>

  <!-- example 2 - Navbar -->
  <div id="main-container" class="container ppg-bg-image">
    <div id="trigger1"></div>
    <div id="ppg-logo" class="mt-4 mb-4">
      <router-link to="/" tag="a" exact><a><img alt="Princess Gentle Paws Pet Sitting & Care" class="img-fluid" src="./assets/ppg-logo.png"></a></router-link>
    </div>
    <PPGNav name="ppg-nav"></PPGNav>
    <router-view></router-view>
    <Footer name="ppg-footer"></Footer>
  </div>
</template>

<script>
import PPGNav from './components/PPGNav.vue'
import Footer from './components/Footer.vue'

import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import { ScrollMagicPluginIndicator} from "scrollmagic-plugins";
ScrollMagicPluginIndicator(ScrollMagic);

export default {
  name: 'App',
  components: {
    PPGNav,
    Footer
  },
  data: function(){
      return{
        controller: undefined,
        scene: undefined
      }
  },
  created() {
    console.log("created");
      this.controller = new ScrollMagic.Controller();
  },
  mounted() {
    console.log("mounted");
    // var arrRefs = new Array("#ppg-logo", "#ppg-nav", "#ppg-footer")
    
    // for(var e=0; e<arrRefs.length; e++){
    //   this.fadeInElement(arrRefs[e]);
    // }     
  },
  methods: {
    fadeInElement (id){
      console.log("fadeInElement");
      console.log("id: " + id)
      
      var tween = new TimelineMax({delay:1, onUpdate:this.updateStats, onRepeat:this.updateReps, onComplete:this.restart});
      tween.from(id, {opacity:0});
      tween.to(id, {opacity:1});

      /*
      this.scene = new ScrollMagic.Scene({
        triggerElement: "#trigger1"
      })
      .setTween(tween) // trigger a TweenMax.to tween
      //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
      .addTo(this.controller);
      */

    },
    updateStats(){
      console.log("updateStats");
    },
    updateReps(){
      console.log("updateReps");
    },
    restart(){
      console.log("restart");
    }
  }
}
</script>

<style lang="scss">

  $lightblue: #64adf2;
  $blue: #1e79d2;
  $bluerollover: #1966b1;
  $darkblue: #061d61;
  $pink: #f7006e;

  body{
    background-color:$lightblue; 
    background-image: url('./assets/bg1.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    font-family: 'Lato', sans-serif;
  }

  h1, h2, h3, h4, h5{
    font-family: 'Arima Madurai', cursive;
  }

  .ppg-bg-white{
    background-color: white;
  }

  .ppg-bg-blue{
    background-color: $blue;
  }

  .ppg-bg-darkblue{
    background-color: $darkblue;
  }

  .ppg-bg-pink{
    background-color: $pink;
  }

  .ppg-text-blue{
    color: $blue;
  }

  .ppg-text-lightblue{
    color: $lightblue;
  }

  .ppg-text-darkblue{
    color: $darkblue;
  }

  .ppg-bg-image{
    background-image: url('/assets/bg1.png');
  }

  .ppg-border-blue{
    border-color: $blue !important;
  }

</style>
